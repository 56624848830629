'use client'

import Image from 'next/image'
import Button from 'components/global/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { useState } from 'react'
import { useSessionStorage } from 'utils/hooks'
import { event } from 'utils/gtm'

import Fondo from './mosca-entrega-rapida.jpg'

const MoscaEntregaRapida = ({ section, tipologia }) => {
    const [displayMoscaEntregaRapida, setDisplayMoscaEntregaRapida] = useSessionStorage('mosca-entregarapida', true)
    const [display, setDisplay] = useState(displayMoscaEntregaRapida)

    if (section !== 'listado' && section !== 'home' && section !== 'coches') return <></>

    if (tipologia !== 'empresas' && tipologia !== 'autonomos' && tipologia !== 'particulares') {
        return <></>
    }

    const handleClick = () => {
        setDisplay(false)
        setDisplayMoscaEntregaRapida(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - entregarapida - esconder' })
    }

    const handleClickButton = (value) => {
        setDisplay(false)
        setDisplayMoscaEntregaRapida(false)
        event({ event: 'gaEvent', location: section, event_name: 'banner', detail: 'mosca - entregarapida - ' + value })
    }

    const link = '/renting/?entregaRapida=true&source=entrega-rapida'

    return (
        <>
            {display && (
                <div className="invisible fixed bottom-[10%] right-0 grid h-[297px] w-[391px] grid-cols-2 overflow-hidden rounded-tl-3xl rounded-br-3xl xl:visible">
                    <Image src={Fondo} alt="" fill className="object-cover" />

                    <div className="absolute right-4 top-4 z-50 cursor-pointer">
                        <FontAwesomeIcon icon={faCircleXmark} className="text-2xl text-white" onClick={handleClick} />
                    </div>
                    <div className="absolute left-28 bottom-12 max-w-[16rem] space-y-4 text-white">
                        <div className="pt-10 text-center">
                            <Button
                                href={link}
                                onClick={() => handleClickButton('me interesa')}
                                variant="default"
                                size="lg"
                                className="px-8"
                            >
                                VER OFERTAS
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default MoscaEntregaRapida
