export function getZapierEndpoint(contact_method, customer_type, location, query_string, session) {
    let EventDefinitionKey = 'APIEvent-eadd2f56-b96b-3ea5-930c-3b3c389186b5'
    let RequestType = ''

    if (location === 'Suscribirme') {
        RequestType = 'Suscribirme Web'
    }
    if (location === 'Motos') {
        RequestType = 'Motos - Piaggio'
    }

    if (location === 'Oferta Marca') {
        let perfil = 'Particulares'
        switch (customer_type) {
            case 'Sole Trader':
                perfil = 'Autonomos'
                break
            case 'SME':
                perfil = 'Empresas'
                break
        }
        let brand = 'SEAT'
        RequestType = `Marca - ${brand} - ${perfil} Oferta`
    }

    if (location === 'Oferta' && customer_type === 'Sole Trader') {
        if (contact_method === 'email') {
            RequestType = 'Oferta - Express - Autonomos - Escribenos'
        }
        if (contact_method === 'date') {
            RequestType = 'Oferta - Express - Autonomos - Llamada'
        }
        if (contact_method === 'call') {
            RequestType = 'Oferta - Express - Autonomos - Llamada Inmediata'
        }
    } else if (location === 'Oferta' && customer_type === 'SME') {
        if (contact_method === 'email') {
            RequestType = 'Oferta - Express - Empresas - Escribenos'
        }
        if (contact_method === 'date') {
            RequestType = 'Oferta - Express - Empresas - Llamada'
        }
        if (contact_method === 'call') {
            RequestType = 'Oferta - Express - Empresas - Llamada Inmediata'
        }
    } else if (location === 'Oferta' && customer_type === 'Private Individual') {
        if (contact_method === 'email') {
            RequestType = 'Oferta - Express - Particulares - Escribenos'
        }
        if (contact_method === 'date') {
            RequestType = 'Oferta - Express - Particulares - Llamada'
        }
        if (contact_method === 'call') {
            RequestType = 'Oferta - Express - Particulares - Llamada Inmediata'
        }
    }

    if (location === 'Oferta' && query_string?.utm_source?.toLowerCase()?.trim() === 'audiq3-interes') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Audi Q3 Banner - Autonomos'
        }
        if (customer_type === 'SME') {
            RequestType = 'Audi Q3 Banner - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Audi Q3 Banner - Autonomos'
        }
    }

    if (location === 'Oferta' && query_string?.utm_source?.toLowerCase()?.trim() === 'qashqai-interes') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Qashqai Banner - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Qashqai Banner - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Qashqai Banner - Autonomos'
        }
    }

    if (location === 'Oferta' && query_string?.utm_source?.toLowerCase()?.trim() === 'polestar-interes') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Polestar Banner - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Polestar Banner - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Polestar Banner - Autonomos'
        }
    }

    if (location === 'Oferta' && query_string?.utm_source?.toLowerCase()?.trim() === 'polestar-testdrive') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Polestar TestDrive - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Polestar TestDrive - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Polestar TestDrive - Autonomos'
        }
    }

    if (
        location === 'Oferta' &&
        (query_string?.utm_campaign?.toUpperCase()?.trim() === 'LSP_RENTINAZO_CONVERSION_NEGOCIO' ||
            query_string?.utm_campaign?.toUpperCase()?.trim() === 'LSP_RENTINAZO_CONVERSION_NONEGOCIO')
    ) {
        if (customer_type === 'Private Individual') {
            RequestType = 'Rentinazo Meta - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Rentinazo Profesionales Meta - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Rentinazo Profesionales Meta - Autonomos'
        }
    }

    if (location === 'Oferta Flexible') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Flexible 50 - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Flexible 50 - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Flexible 50 - Autonomos'
        }
    }

    if (location === 'Oferta Flexible10') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Flexible 10 - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Flexible 10 - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Flexible 10 - Autonomos'
        }
    }

    if (location === 'Oferta UCL') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Renting - UCL - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Renting - UCL - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Renting - UCL - Autonomos'
        }
    }

    if (
        location === 'Oferta UCL' &&
        (query_string?.utm_campaign?.toUpperCase()?.trim() === 'LSP_RENTINAZO_CONVERSION_NEGOCIO' ||
            query_string?.utm_campaign?.toUpperCase()?.trim() === 'LSP_RENTINAZO_CONVERSION_NONEGOCIO')
    ) {
        if (customer_type === 'Private Individual') {
            RequestType = 'Rentinazo Meta - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Rentinazo Profesionales Meta - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Rentinazo Profesionales Meta - Autonomos'
        }
    }

    if (location === 'Cualificador') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Web - Cualificacion Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Web - Cualificacion Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Web - Cualificacion Autonomos'
        }
    }

    if (location === 'Landing-Rentinazo') {
        if (customer_type === 'Private Individual') {
            if (contact_method === 'llamada') {
                RequestType = 'Rentinazo - Form Llamada Particulares'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Form Llamada Particulares'
            } else if (contact_method === 'escribenos') {
                RequestType = 'Rentinazo - Form Escribenos Particulares'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Form Escribenos Particulares'
            } else {
                RequestType = 'Rentinazo - Particulares'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Particulares'
            }
        }
        if (customer_type === 'SME') {
            if (contact_method === 'llamada') {
                RequestType = 'Rentinazo - Form Llamada Empresas'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Form Llamada Empresas'
            } else if (contact_method === 'escribenos') {
                RequestType = 'Rentinazo - Form Escribenos Empresas'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Form Escribenos Empresas'
            } else {
                RequestType = 'Rentinazo - Empresas'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Empresas'
            }
        }
        if (customer_type === 'Sole Trader') {
            if (contact_method === 'llamada') {
                RequestType = 'Rentinazo - Form Llamada Autonomos'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Form Llamada Autonomos'
            } else if (contact_method === 'escribenos') {
                RequestType = 'Rentinazo - Form Escribenos Autonomos'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Form Escribenos Autonomos'
            } else {
                RequestType = 'Rentinazo - Autonomos'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Meta - Autonomos'
            }
        }
    }

    if (location === 'Landing-Rentinazo-Profesionales') {
        if (customer_type === 'Private Individual') {
            if (contact_method === 'llamada') {
                RequestType = 'Rentinazo Profesionales - Form Llamada Particulares'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Form Llamada Particulares'
            } else if (contact_method === 'escribenos') {
                RequestType = 'Rentinazo Profesionales - Form Escribenos Particulares'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Form Escribenos Particulares'
            } else {
                RequestType = 'Rentinazo Profesionales - Particulares'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Particulares'
            }
        }
        if (customer_type === 'SME') {
            if (contact_method === 'llamada') {
                RequestType = 'Rentinazo Profesionales - Form Llamada Empresas'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Form Llamada Empresas'
            } else if (contact_method === 'escribenos') {
                RequestType = 'Rentinazo Profesionales - Form Llamada Empresas'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Form Escribenos Empresas'
            } else {
                RequestType = 'Rentinazo Profesionales - Empresas'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Empresas'
            }
        }
        if (customer_type === 'Sole Trader') {
            if (contact_method === 'llamada') {
                RequestType = 'Rentinazo Profesionales - Form Llamada Autonomos'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Form Llamada Autonomos'
            } else if (contact_method === 'escribenos') {
                RequestType = 'Rentinazo Profesionales - Form Escribenos Autonomos'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Form Escribenos Autonomos'
            } else {
                RequestType = 'Rentinazo Profesionales - Autonomos'
                if (query_string?.utm_source?.toLowerCase()?.trim() === 'facebook')
                    RequestType = 'Rentinazo Profesionales Meta - Autonomos'
            }
        }
    }

    if (location === 'Landing-Aniversario') {
        if (query_string?.utm_campaign === 'LSP_60ANIVERSARIO_CONVERSION_NONEGOCIO') {
            if (customer_type === 'Private Individual') {
                if (contact_method === 'llamada') {
                    RequestType = '60 Aniversario Meta - Form Llamada Particulares'
                } else if (contact_method === 'escribenos') {
                    RequestType = '60 Aniversario Meta - Form Escribenos Particulares'
                } else {
                    RequestType = '60 Aniversario Meta - Particulares'
                }
            }
            if (customer_type === 'SME') {
                if (contact_method === 'llamada') {
                    RequestType = '60 Aniversario Meta - Form Llamada Empresas'
                } else if (contact_method === 'escribenos') {
                    RequestType = '60 Aniversario Meta - Form Escribenos Empresas'
                } else {
                    RequestType = '60 Aniversario Meta - Empresas'
                }
            }
            if (customer_type === 'Sole Trader') {
                if (contact_method === 'llamada') {
                    RequestType = '60 Aniversario Meta - Form Llamada Autonomos'
                } else if (contact_method === 'escribenos') {
                    RequestType = '60 Aniversario Meta - Form Escribenos Autonomos'
                } else {
                    RequestType = '60 Aniversario Meta - Autonomos'
                }
            }
        } else {
            if (customer_type === 'Private Individual') {
                if (contact_method === 'llamada') {
                    RequestType = '60 Aniversario - Form Llamada Particulares'
                } else if (contact_method === 'escribenos') {
                    RequestType = '60 Aniversario - Form Escribenos Particulares'
                } else {
                    RequestType = '60 Aniversario - Particulares'
                }
            }
            if (customer_type === 'SME') {
                if (contact_method === 'llamada') {
                    RequestType = '60 Aniversario - Form Llamada Empresas'
                } else if (contact_method === 'escribenos') {
                    RequestType = '60 Aniversario - Form Escribenos Empresas'
                } else {
                    RequestType = '60 Aniversario - Empresas'
                }
            }
            if (customer_type === 'Sole Trader') {
                if (contact_method === 'llamada') {
                    RequestType = '60 Aniversario - Form Llamada Autonomos'
                } else if (contact_method === 'escribenos') {
                    RequestType = '60 Aniversario - Form Escribenos Autonomos'
                } else {
                    RequestType = '60 Aniversario - Autonomos'
                }
            }
        }
    }

    if (location === 'Landing-Renting-Ofertas') {
        if (query_string?.utm_source?.trim() === 'bing' && query_string?.utm_medium?.trim() === 'cpc') {
            if (contact_method !== 'personalizada') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Landing Edu BING - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Landing Edu BING - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Landing Edu BING - Autonomos'
                }
            } else {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Landing Edu BING No Ofertas - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Landing Edu BING No Ofertas - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Landing Edu BING No Ofertas - Autonomos'
                }
            }
        } else if (
            query_string?.utm_campaign?.trim() === 'DESCOMPLICATE_CONVERSION_NEGOCIO' ||
            query_string?.utm_campaign?.trim() === 'DESCOMPLICATE_CONVERSION_NONEGOCIO'
        ) {
            if (customer_type === 'Private Individual') {
                if (contact_method === 'llamada') {
                    RequestType = 'Descomplicate - Particulares'
                } else if (contact_method === 'escribenos') {
                    RequestType = 'Descomplicate - Particulares'
                } else {
                    RequestType = 'Descomplicate - Particulares'
                }
            }
            if (customer_type === 'SME') {
                if (contact_method === 'llamada') {
                    RequestType = 'Descomplicate - Empresas'
                } else if (contact_method === 'escribenos') {
                    RequestType = 'Descomplicate - Empresas'
                } else {
                    RequestType = 'Descomplicate - Empresas'
                }
            }
            if (customer_type === 'Sole Trader') {
                if (contact_method === 'llamada') {
                    RequestType = 'Descomplicate - Autonomos'
                } else if (contact_method === 'escribenos') {
                    RequestType = 'Descomplicate - Autonomos'
                } else {
                    RequestType = 'Descomplicate - Autonomos'
                }
            }
        } else {
            if (contact_method === 'personalizada') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Landing Edu No Ofertas - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Landing Edu No Ofertas - Autonomos'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Landing Edu No Ofertas - Empresas'
                }
            } else {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Landing Edu - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Landing Edu - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Landing Edu - Autonomos'
                }
            }
        }
    }

    if (location === 'Landing-Ofertas-Renting-Coches') {
        if (customer_type === 'Private Individual') {
            RequestType = 'Landing Inc - Particulares'
        }
        if (customer_type === 'SME') {
            RequestType = 'Landing Inc - Empresas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'Landing Inc - Autonomos'
        }
    }

    if (location === 'KM77') {
        if (customer_type === 'Individual') {
            RequestType = 'KM77 - Particulares - No Ofertas'
        }
        if (customer_type === 'Sole Trader') {
            RequestType = 'KM77 - Autonomos - No Ofertas'
        }
        if (customer_type === 'SME') {
            RequestType = 'KM77 - Empresas - No Ofertas'
        }
    }

    if (location === 'Contacto' && contact_method === 'Escribenos') {
        RequestType = 'Contacto - Escribenos'
    } else if (location === 'Contacto' && contact_method === 'Llamada') {
        RequestType = 'Contacto - Llamada'
    } else if (location === 'Contacto' && contact_method === 'Llamada Inmediata') {
        RequestType = 'Contacto - Llamada Inmediata'
    } else if (location === 'Contacto' && contact_method === 'No Encuentras') {
        RequestType = 'LMODR/Noencentruas'
    }

    if (session || query_string) {
        if (
            query_string?.utm_campaign === 'LSP_ENTREGARAPIDA_CONVERSION_NONEGOCIO' ||
            (session?.medium === 'paidsocial' && session?.campaign === 'LSP_ENTREGARAPIDA_CONVERSION_NEGOCIO')
        ) {
            if (customer_type === 'Private Individual') {
                RequestType = 'Entrega Rapida Meta - Particulares'
            }
            if (customer_type === 'SME') {
                RequestType = 'Entrega Rapida Meta - Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Entrega Rapida Meta - Autonomos'
            }
        }

        if (
            query_string?.utm_campaign === 'LSP_ENTREGARAPIDA_CONVERSION_NEGOCIO' ||
            (session?.medium === 'paidsocial' && session?.campaign === 'LSP_ENTREGARAPIDA_CONVERSION_NEGOCIO')
        ) {
            if (customer_type === 'Private Individual') {
                RequestType = 'Entrega Rapida Meta - Particulares'
            }
            if (customer_type === 'SME') {
                RequestType = 'Entrega Rapida Meta - Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Entrega Rapida Meta - Autonomos'
            }
        }
        if (session?.medium === 'email' && session?.campaign === 'newsletter') {
            if (customer_type === 'Private Individual') {
                RequestType = 'Newsletter - Autonomos'
            }
            if (customer_type === 'SME') {
                RequestType = 'Newsletter - Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Newsletter - Autonomos'
            }
        }
    }

    if (session) {
        if (session?.source === 'restricciones-interes') {
            if (customer_type === 'Private Individual') {
                RequestType = 'Restricciones Banner - Particulares'
            }
            if (customer_type === 'SME') {
                RequestType = 'Restricciones Banner - Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Restricciones Banner - Autonomos'
            }
        }
        if (session?.source === 'entrega-rapida') {
            if (customer_type === 'Private Individual') {
                RequestType = 'Entrega rapida Banner -Particulares'
            }
            if (customer_type === 'SME') {
                RequestType = 'Entrega rapida Banner -Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Entrega rapida Banner - Autonomos'
            }
        }

        if (session?.medium === 'paidsocial' && session?.campaign === 'LSP_ENTREGARAPIDA_CONVERSION_NONEGOCIO') {
            if (customer_type === 'Private Individual') {
                RequestType = 'Entrega Rapida Meta - Particulares'
            }
            if (customer_type === 'SME') {
                RequestType = 'Entrega Rapida Meta - Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Entrega Rapida Meta - Autonomos'
            }
        }
        if (
            session?.medium === 'paidsocial' &&
            (session?.campaign === 'LSP_RENTINGDISFRUTA_CONVERSION_NONEGOCIO' ||
                session?.campaign === 'LSP_RENTINGDISFRUTA_CONVERSION_NEGOCIO')
        ) {
            if (customer_type === 'Private Individual') {
                RequestType = 'Renting Disfruta META - Particulares'
            }
            if (customer_type === 'SME') {
                RequestType = 'Renting Disfruta META - Empresas'
            }
            if (customer_type === 'Sole Trader') {
                RequestType = 'Renting Disfruta META - Autonomos'
            }
        }

        if (session?.campaign === 'opplost' && session?.medium === 'email') {
            if (session?.term === 'vhnotrequired') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Vhnotrequired - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Vhnotrequired - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Vhnotrequired - Particulares'
                }
            }
            if (session?.term === 'carspeed') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Car Speed - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Car Speed - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Car Speed - Particulares'
                }
            }
            if (session?.term === 'competitor') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Competitor - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Competitor - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Competitor - Particulares'
                }
            }
            if (session?.term === 'credit') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Credit - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Credit - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Credit - Particulares'
                }
            }
            if (session?.term === 'financelease') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Finance Lease - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Finance Lease - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Finance Lease - Particulares'
                }
            }
            if (session?.term === 'purchase') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Purchase - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Purchase - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Purchase - Particulares'
                }
            }
            if (session?.term === 'price') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Price - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Price - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Price - Particulares'
                }
            }
            if (session?.term === 'product') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Product - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Product - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Product - Particulares'
                }
            }
            if (session?.term === 'usedcar') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Used Car - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Used Car - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Used Car - Particulares'
                }
            }
            if (session?.term === 'expirado') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Expirado - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Expirado - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Expirado - Particulares'
                }
            }
            if (session?.term === 'rechazado') {
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Opp Perdidas Rechazado - Autonomos'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Opp Perdidas Rechazado - Empresas'
                }
                if (customer_type === 'Private Individual') {
                    RequestType = 'Opp Perdidas Rechazado - Particulares'
                }
            }
        }

        if (session?.campaign === 'leadsperdidos' && session?.medium === 'email') {
            if (session?.term === 'choosecompetitor') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Choose Competitor - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Choose Competitor - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Choose Competitor - Autonomos'
                }
            }

            if (session?.term === 'generalrejected') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos General Rejected - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos General Rejected - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos General Rejected - Autonomos'
                }
            }

            if (session?.term === 'ilocalizable') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Ilocalizable - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Ilocalizable - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Ilocalizable - Autonomos'
                }
            }

            if (session?.term === 'justcuriosity') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Just Curiosity - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Just Curiosity - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Just Curiosity - Autonomos'
                }
            }

            if (session?.term === 'noanswer') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos No Answer - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos No Answer - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos No Answer - Autonomos'
                }
            }

            if (session?.term === 'financelease') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Finance Lease - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Finance Lease - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Finance Lease - Autonomos'
                }
            }

            if (session?.term === 'purchase') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Purchase - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Purchase - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Purchase - Autonomos'
                }
            }

            if (session?.term === 'price') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Price - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Price - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Price - Autonomos'
                }
            }

            if (session?.term === 'vhnotallowed') {
                if (customer_type === 'Private Individual') {
                    RequestType = 'Leads Perdidos Vhnotallowed - Particulares'
                }
                if (customer_type === 'SME') {
                    RequestType = 'Leads Perdidos Vhnotallowed - Empresas'
                }
                if (customer_type === 'Sole Trader') {
                    RequestType = 'Leads Perdidos Vhnotallowed - Autonomos'
                }
            }
        }
    }

    const result = {
        endpoint: 'https://hooks.zapier.com/hooks/catch/5818753/o0a80z7/',
        EventDefinitionKey: EventDefinitionKey,
        RequestType: RequestType,
    }
    return result
}

export function getPardotEndpoint(customer_type) {
    let tipologia =
        customer_type === 'SME'
            ? 'email_empresa'
            : customer_type === 'Sole Trader'
            ? 'email_autonomo'
            : 'email_particular'
    if (customer_type === 'email' || customer_type === 'call' || customer_type === 'date') {
        tipologia = customer_type
    }
    const endpoints = {
        email: {
            url: 'l/192702/2017-03-28/4dmb8',
            piAId: '193702',
            piCId: '5953',
        },
        date: {
            url: 'l/192702/2017-03-28/4dmbb',
            piAId: '193702',
            piCId: '5953',
        },
        call: {
            url: 'l/192702/2017-03-28/4dmbs',
            piAId: '193702',
            piCId: '5953',
        },
        email_particular: {
            url: 'l/192702/2017-04-17/4q73g',
            piAId: '193702',
            piCId: '5959',
        },
        date_particular: {
            url: 'l/192702/2017-04-17/4q72d',
            piAId: '193702',
            piCId: '5959',
        },
        call_particular: {
            url: 'l/192702/2017-04-17/4q72j',
            piAId: '193702',
            piCId: '5961',
        },

        email_empresa: {
            url: 'l/192702/2017-04-17/4q73d',
            piAId: '193702',
            piCId: '5959',
        },
        date_empresa: {
            url: 'l/192702/2017-03-30/4hmwv',
            piAId: '193702',
            piCId: '5959',
        },
        call_empresa: {
            url: 'l/192702/2017-03-30/4hmwx',
            piAId: '193702',
            piCId: '5961',
        },

        email_autonomo: {
            url: 'l/192702/2017-04-17/4q74j',
            piAId: '193702',
            piCId: '5959',
        },
        date_autonomo: {
            url: 'l/192702/2017-04-17/4q72b',
            piAId: '193702',
            piCId: '5959',
        },
        call_autonomo: {
            url: 'l/192702/2017-04-17/4q72g ',
            piAId: '193702',
            piCId: '5961',
        },
    }
    console.log('tipologia', tipologia)
    const key = `${tipologia}`
    const url = `https://contacto.leaseplango.es/${endpoints[key].url}`
    return url
}

export const llamadaInmediataActiva = function () {
    const d = new Date()
    const day = d.getUTCDay()
    const hour = d.getUTCHours()
    let result = true
    if (hour > 15 || hour < 7) result = false
    if (day === 0 || day === 6) result = false
    return result
}

export const convertFormToJSON = function (form) {
    const formData = new FormData(form)
    const pairs = {}
    for (const [name, value] of formData) {
        pairs[name] = value
    }
    return pairs
}

export const convertObjectToFormData = (data) => {
    const formData = new FormData()
    Object.keys(data).map((name) => {
        formData.append(name, data[name])
    })
    return formData
}

export const launchPixel = function (url) {
    const pixel = new Image()
    pixel.width = 1
    pixel.height = 1
    pixel.src = url
    document.body.appendChild(pixel)
}

export const emailHash = async function (string, clean) {
    if (clean) string = string.trim().toLowerCase()
    const utf8 = new TextEncoder().encode(string)
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
    return hashHex
}
